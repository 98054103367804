.wrapper {
  padding: 24px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.errorText {
  color: var(--error-text);
}
