.brokenCardWrapper {
  background-color: black;
  height: 100%;
  color: #fff;
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.brokenCard {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  gap: 10px;
}

.header {
  font-size: 40px;
  font-weight: bold;
  line-height: 35px;
  color: #fff;
}

.text {
  font-size: 14px;
  word-wrap: break-word;
  text-align: center;
  line-height: 24px;
  color: #fff;
  letter-spacing: 2px;
}

.logoWrapper {
  svg {
    color: #fff;
    width: 72px;
    height: auto;
  }
}
