.wrapper {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  background-color: #fff;
  border: 2px solid var(--error-text);
  padding: 40px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  h1 {
    font-size: 24px;
    font-weight: 700;
    color: var(--error-text);
  }

  svg {
    fill: var(--error-text);
    width: 50px;
    height: 50px;
  }
}
