.audioPlayer {
  &::-webkit-media-controls-play-button {
    border: 1px solid red;
  }
}

.audioPlayerWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.timeWrapper {
  display: flex;
  justify-content: center;

  div {
    font-weight: bold;
    font-size: 14px;
  }
}

.progressBar {
  background-color: transparent;
  border: 2px solid black;
  border-radius: 10px;
  position: relative;
  height: 20px;
  appearance: none;
  padding-left: 7px;
  padding-right: 7px;

  &::before {
    content: "";
    height: 11px;
    width: 50px;
    background-color: aqua;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 5px;
    border: none;
    background-color: black;

    position: relative;
    margin: -1px 0 0 0;
    z-index: 0;
    box-sizing: border-box;
    margin-bottom: -1px;
  }
}

.playPauseButton {
  background-color: transparent;
  border: none;
  margin: 0 auto;
  height: 35px;

  &:hover {
    cursor: pointer;
  }
}

.playButton {
  width: 30px;
  height: 30px;
}

.pauseButton {
  width: 15px;
  height: 15px;
  display: flex;
  gap: 5px;

  div {
    width: 10px;
    height: 30px;
    background-color: black;
    border-radius: 5px;
  }
}
