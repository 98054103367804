.wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.accountMenuDisplay {
  padding: 8px;
  border: 1px solid var(--primary-07);
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--primary-white);
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }
}

.logoWrapper {
  display: flex;
  align-self: center;

  svg {
    width: 100px;
    height: auto;
    color: var(--primary-07);
  }
}

.avatar {
  &:hover {
    cursor: pointer;
  }
}

.emailWrapper {
  border-bottom: 1px solid var(--primary-09) !important;
}

.spawningWrapper {
  border-top: 1px solid var(--primary-09) !important;
}

.menuItem {
  padding: 8px 24px !important;
  font-family: var(--font-forma) !important;
  transition: 1s !important;

  &:hover {
    .menuItemContent {
      cursor: pointer;

      svg {
        visibility: visible;
        opacity: 1;
        margin-left: -16px;
        width: 15px;
        height: 15px;
      }
    }
  }
}

.menuItemContent {
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }
}
