.avatar {
  text-transform: capitalize;
  background-color: #d1d1d1;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
