.searchBar {
  width: 45vw;
  min-width: 170px;

  div {
    border-radius: 40px;
    transition: all 0.2s ease-in-out;

    input {
      padding-left: 8px;
      font-family: var(--font-forma);
      font-size: 18px;
    }
  }

  &:focus-within {
    div {
      box-shadow: 0px 0px 6px 0px #000000;
      // transform: scale(1.1);
      transition: 0.2s;
    }
  }
}

.dropdownMenu {
  background-color: #fff;
  border: 1px solid var(--primary-05);
  border-radius: 8px;
  width: 99%;
  margin: 0 auto;
  margin-top: 16px;
  margin-top: 4px;
  max-height: 40vh;
  height: auto;
  overflow: scroll;
  transition: 0.2s;
  z-index: 999;

  &.hidden {
    visibility: hidden;
  }
}

.dropdownMenuSectionHeader {
  background-color: var(--primary-01);
  margin: 0 4px;
  border-radius: 4px;
  padding: 6px 16px;

  span {
    font-family: var(--font-forma);
    font-weight: bold;
    letter-spacing: 1px;
  }

  &:hover {
    background-color: var(--primary-01);
    cursor: auto;
  }
}

.dropdownItem {
  margin: 0 4px;

  &:focus {
    background-color: var(--primary-00);
  }
}

.domainWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.dropdownItem {
    align-items: flex-start;
  }

  .total {
    color: var(--primary-05);
  }
}

@media screen and (max-width: 850px) {
  .searchBar {
    width: 40vw;
  }
}

@media screen and (max-width: 760px) {
  .searchBar {
    width: 60vw;
  }

  .dropdownMenu {
    &.hidden {
      display: none;
    }
  }

  .dropDownItemText {
    > span {
      max-width: 50vw;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
