.navWrapper {
  width: 100vw;
}

.navContent {
  display: flex;
  padding: 20px 40px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.logoWrapper {
  svg {
    width: auto;
    height: 32px;
    min-width: 200px;
    max-width: 300px;
  }

  &:hover {
    cursor: pointer;
  }
}

.navSearchBar {
  height: 39px;

  div {
    div {
      input {
        padding: 0 !important;
      }
    }
  }
}

.dropDownMenu {
  height: 60vh;
}

.logoWrapperMobile {
  display: none;
}

.homePageLogo {
  svg {
    width: 32px;
    height: auto;

    g {
      fill: #000;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 850px) {
}

@media screen and (max-width: 760px) {
  .navContent {
    padding: 10px 20px;
    gap: 8px;
  }

  .logoWrapper {
    svg {
      width: 100%;
      min-width: 150px;
      max-width: 200px;
    }
  }

  .signInButton {
    padding: 4px !important;
    width: 56px !important;
    font-size: 12px;
  }

  .logoWrapperMobile {
    display: block;
    svg {
      width: 32px;
      height: auto;

      g {
        fill: #000;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .logoWrapper {
    display: none;
  }
}
