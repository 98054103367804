.wrapper {
  padding: 40px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.buttonsWrapper {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  width: 100%;

  button {
    width: 180px;
  }
}

.secondaryButtonsWraper {
  display: flex;
  gap: 8px;
  margin-top: 26px;
  justify-content: space-between;
}

.errorText {
  color: #cc4000;
}

@media screen and (max-width: 620px) {
  .buttonsWrapper {
    flex-direction: column;
    align-items: center;
  }

  .secondaryButtonsWraper {
    flex-direction: column;
    align-items: center;
  }
}

/* google auth css */

.googleSignInButtonWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #ddd; /* Color and thickness of the line */
  margin: 0 10px;
}
