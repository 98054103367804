.wrapper {
  position: fixed;
  inset: 0;
  background-color: rgba(56, 56, 56, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  z-index: 9999;
}

.content {
  background-color: white;
  width: 50vh;
  border-radius: 24px;
}

.successStateWrapper {
  padding: 40px;
}

@media screen and (max-width: 620px) {
  .content {
    background-color: white;
    width: 80vw;
    border-radius: 24px;
  }
}
