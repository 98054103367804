.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}

.progressBarWrapper {
  height: 10px;
  width: 100%;
  background-color: var(--primary-01);
  border-radius: 8px;
}

.progressBar {
  height: 9px;
  border-radius: 8px;
  transition: 0.5s ease-in-out;
}

.passwordInfoText {
  font-family: var(--font-signifier);
}

.passwordMessageWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.warning {
  background-color: var(--error-text) !important;
}

.veryWeak {
  background-color: var(--primary-05);
}

.weak {
  background-color: var(--primary-05);
}
.fair {
  background-color: var(--primary-05);
}
.good {
  background-color: var(--success-text);
}
.strong {
  background-color: var(--success-text);
}

.warningText {
  text-align: end;
  color: var(--error-text);
  font-size: 16px;
}
