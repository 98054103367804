.passwordRule {
  display: flex;
  align-items: center;
  gap: 4px;

  &.passwordRuleSuccess {
    opacity: 0.4;
  }
}

.passwordRuleText {
  font-family: var(--font-signifier);
}
