.wrapper {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.triangle {
  width: 0;
  height: 0;
  border-bottom: 50px solid var(--primary-03);
  border-left: 50px solid transparent;

  &.approved {
    border-bottom: 50px solid #3797e7;
  }

  &.rejected {
    border-bottom: 50px solid #ff5000;
  }
}

.iconButton {
  position: absolute !important;
  right: 8% !important;
  bottom: 11% !important;
  padding: 0 !important;
}

.icon {
  position: absolute;
  right: 8%;
  bottom: 11%;
}

.rejectedPopperContent {
  background-color: var(--primary-01);
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 304px;
  background-color: white;
  border: 2px solid black;
  border-radius: 8px;
  font-family: var(--font-signifier);
}

.popoverButtons {
  display: flex;
  justify-content: center;
  gap: 24px;
}
