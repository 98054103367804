// checkbox

.checkboxInput[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 24px;
  height: 24px;
  border-radius: 0.35em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  box-shadow: 0 0 1px 1px var(--primary-05);

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 1px 1px var(--primary-05);
    border: 0.15em solid currentColor;
  }
}

.checkboxInput[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 0.5em 0.5em #000;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.checkboxInput[type="checkbox"]:checked::before {
  transform: scale(1);
}

.checkboxInput[type="checkbox"]:checked {
  background-color: #d1d1d1;
}

.itemActions {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
}

.checkBoxLabel {
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    cursor: pointer;
  }
}
