.wrapper {
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background-color: white;
  height: 100%;
  border-radius: 24px;
  margin-top: 24px;
}

.cross {
  svg {
    width: 200px;
    height: 200px;
  }
}

.children {
  font-size: 20px;
  text-align: center;
}
