.button {
  padding: 8px 14px;
  border: transparent;
  font-family: var(--font-forma-bold);
  background-color: var(--primary-11);
  color: #fff;
  font-size: 16px;
  border-radius: 24px;
  transition: 0.2s;

  &.small {
    padding: 4px 8px;
    font-size: 14px;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--primary-08);
    border-color: var(--primary-08);
    transform: scale(1.05);
  }

  &:focus {
    background-color: var(--primary-10);
    box-shadow: 0px 0px 3px 2px var(--primary-05);
    border-color: var(--primary-08);
    transform: scale(1.05);
    outline: none;
  }

  &.secondary {
    background-color: #fff;
    border: 2px solid var(--primary-11);
    color: var(--primary-11);

    &:hover {
      background-color: var(--primary-00);

      transform: scale(1.05);
    }

    &:focus {
      background-color: var(--primary-00);
      outline: none;

      transform: scale(1.05);
    }
  }

  &.disabled {
    background-color: var(--primary-01);
    border: 2px solid var(--primary-11);
    color: var(--primary-09);
    opacity: 0.5;

    &:hover {
      cursor: auto;
      transform: none;
    }
  }
}

.textButton {
  background: transparent;
  border: none;
  font-size: 16px;
  font-family: var(--font-forma);
  display: flex;
  align-items: center;
  transition: 0.1s;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
    border-bottom: 2px solid var(--primary-11);
  }
}

.socialSignInButton {
  -moz-user-select: none;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #f2f2f2;
  background-image: none;
  border: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: "Roboto", arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  position: relative;
  text-align: center;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  -webkit-transition: background-color 0.218s, border-color 0.218s,
    box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.socialSignInButtonIcon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.socialSignInButtonContentWrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.socialSignInButtonContents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: "Roboto", arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.socialSignInButtonState {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.socialSignInButton:disabled {
  cursor: default;
  background-color: #ffffff61;
}

.socialSignInButton:disabled .socialSignInButtonState {
  background-color: #1f1f1f1f;
}

.socialSignInButton:disabled .socialSignInButtonContents {
  opacity: 38%;
}

.socialSignInButton:disabled .socialSignInButtonIcon {
  opacity: 38%;
}

.socialSignInButton:not(:disabled):active .socialSignInButtonState,
.socialSignInButton:not(:disabled):focus .socialSignInButtonState {
  background-color: #001d35;
  opacity: 12%;
}

.socialSignInButton:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.socialSignInButton:not(:disabled):hover .socialSignInButtonState {
  background-color: #001d35;
  opacity: 8%;
}
