.wrapper {
  background-color: rgba(56, 56, 56, 0.7);
  position: fixed;
  inset: 0;
  z-index: 999;
  overflow: scroll;
}

.content {
  background-color: #efefef;
  padding: 40px;
  margin: 40px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: 16px;
  min-width: 250px;
  overflow-x: hidden;
}

.tableWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.closeButton {
  display: flex;
  align-self: flex-end;
}

.imageWrapper {
  margin-top: -14px;
  position: relative;
  height: 350px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}

.infoWrapper {
  width: 60vw;
  margin: 0 auto;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0;

  .mltBadge {
    border-radius: 30px;
    padding: 8px;
    margin-top: -17px;
    color: #fff;
    background-color: #000;
  }
}

.sourceWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  gap: 2px;

  .source {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
  }

  span {
    color: var(--primary-06);
  }

  a {
    padding: 0;
    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.tableAndControlsWrapper {
  display: flex;
  flex-direction: column;
}

// table controls
.controlsWrapper {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: var(--primary-01);
  padding: 20px;
}

.controlsContent {
  display: flex;
  justify-content: space-between;
}

.actionsWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.doNotTrainButton {
  padding: 8px;
  border-radius: 39px;
  height: min-content;
  border: 1px solid var(--primary-07);
  font-weight: 400;

  &:hover {
    cursor: pointer;
  }
}

.doNotTrainButtonWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

@media screen and (max-width: 650px) {
  .imageWrapper {
    height: 150px;
  }
}

@media screen and (max-width: 520px) {
  .controlsContent {
    flex-direction: column;
    gap: 16px;
  }

  .doNotTrainButton {
    align-self: flex-start;
  }
}
