.dataGridCardWrapper {
  background-color: #fff;
  border-radius: 8px;
  color: #1c1c1c;
}

.cardHeader {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
}

.typeLozenge {
  padding: 0 4px;
  font-size: 14px;
  text-transform: lowercase;
  border: 1px solid black;
  border-radius: 16px;
}

.sourceLozenge {
  padding: 0 4px;
  font-size: 14px;
  border: 1px solid black;
  border-radius: 16px;
}

.dataGridCard {
  width: 100%;
  height: 200px;
  background-size: contain;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
}

.elementWrapper {
  position: relative;
}

.captionWrapper {
  height: min-content;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;

  .caption,
  .captionButton {
    font-size: 14px;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: none;
    outline: none;
    border: none;
    text-align: start;
    padding: 0;
    font-family: var(--font-signifier);

    &.noCaption {
      color: var(--primary-07);
      font-style: italic;
    }
  }
}
.sourceWrapper {
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 2px;

  .source {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
  }

  span {
    color: var(--primary-06);
  }

  a {
    padding: 0;
    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.audioPlayerWrapper,
.videoPlayerWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0 24px;
}

.cardFooter {
  display: flex;
  flex-direction: column;
  padding: 8px 8px;
}

.divider {
  height: 1px;
  background-color: #000;
}

.actionsBar {
  display: flex;
  justify-content: space-between;
}

.iconButton {
  &:disabled {
    opacity: 0.3;
  }
}
