.container {
  background-color: var(--primary-01);
  padding: 16px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.itemsWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
  max-width: 90vw;
  width: 100%;
  gap: 20px;
  justify-content: center;
  margin: 0 auto;
}
