.wrapper {
  padding: 24px;
}

.form {
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.errorText {
  color: var(--error-text);
}
